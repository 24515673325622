body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reject-modal-title {
  color: #212a39;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px; /* 133.333% */
}

.reject-modal-text {
  color: #212a39;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.modal-yes-btn {
  display: flex;
  width: 120px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #035642 !important;
  margin-right: 4px !important;
  text-transform: none !important;
}

.approved-btn {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  gap: 8px;
  border-radius: 4px;
  background: #035642 !important;
  margin-right: 4px !important;
  text-transform: none !important;
  opacity: 70% !important;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.612);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays everything */
}

.spinner-container {
  position: relative;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #014644;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}
.brand-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px; /* Adjust size as needed */
  height: 85px; /* Adjust size as needed */
  border-radius: 80px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.send-rmdr-btn {
  display: flex;
  width: 100%;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(60, 72, 82, 0.1) !important;
  margin-right: 4px !important;
  color: #035642 !important;
  text-transform: none !important;
}

.loader-box {
  display: flex;
  justify-content: center;
  width: 80%;
  top: 50%;
  position: fixed;
}
.send-rmndr-txt {
  color: #035642;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-no-btn {
  display: flex;
  width: 120px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #dc3545 !important;
  margin-left: 4px !important;
  text-transform: none !important;
}

.searchby-box {
  color: #575e64;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  display: flex;
  /* width: 323px; */
  padding: 11px 8px 7px 16px;
  align-items: center;
  gap: 192px;
}

.searchval-box {
  color: #575e64;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  display: flex;
  width: 323px;
  padding: 11px 8px 7px 16px;
  align-items: center;
  gap: 192px;
  margin-left: 16px;
}

.nsdc-search-btn {
  display: flex;
  padding: 11px 24px 11px 24px;
  align-items: center;
  gap: 192px;
  border-radius: 4px;
  border: 1.5px solid #055646;
  background: #fff;
  margin-left: 16px;
  font-weight: 800;
  color: #055646;
}

.nsdc-reset-btn {
  display: flex;
  padding: 11px 24px 11px 24px;
  align-items: center;
  gap: 192px;
  border-radius: 4px;
  border: 1.5px solid #f82828;
  background: #fff;
  margin-left: 16px;
  font-weight: 800;
  color: #ff3a09;
}

.nsdc-link {
  text-decoration: none;
  color: inherit;
}

.nsdc-link:hover {
  color: blue !important;
}

.modal-buttonRow {
  display: flex;
  justify-content: center;
  margin-top: 24px !important;
}
.submit-modal-title {
  color: #a09fa0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;
}

.modal-submit-img {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
